import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Employeeclass } from '../classfile/employeeclass';
import { Experienceclass } from '../classfile/experienceclass';
import { Referanceclass } from '../classfile/referanceclass';
import { InterviewService } from 'src/app/services/interview.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';


@Component({
  selector: 'app-level2',
  templateUrl: './level2.component.html',
  styleUrls: ['./level2.component.css']
})
export class Level2Component implements OnInit {
  PersonaldetailsForm: FormGroup; 
  bankdetailsForm:FormGroup;
  experiencedetailsForm:FormGroup;
  referencedetailsForm:FormGroup;
  otherdetailsForm:FormGroup;
  documentssubmittedForm:FormGroup;
  jobdetailsForm:FormGroup;
  fname: FormControl;
  mname: FormControl;
  lname: FormControl;
  msts: FormControl;
  cno: FormControl;
  dob: FormControl;
  email:FormControl;
  acno:FormControl;
  pcno:FormControl;
  passno:FormControl;
  bolgrp:FormControl;
  Radd:FormControl;
  pcode:FormControl;
  // bank details
  bname:FormControl;
  bahname:FormControl;
  bano:FormControl;
  nrcode:FormControl;
  badd:FormControl;
  // EXPERIENCE DETAILS
 cpyname1:FormControl;
 period :FormControl;
//  Reference details
refname1:FormControl;
ref1contno:FormControl;
// other details
alpfno:FormBuilder;
uanno:FormControl;
pfno:FormControl;

// doucmets sumitted
documentname:FormControl
documentfile:FormControl;


// job details
jdone:FormControl;
doj:FormControl;
cofdt:FormControl;
Location:FormControl;
Project:FormControl;
reptsto:FormControl;
GetEmployeeExperience;
empid;
submited = new EventEmitter<string>();
DocumentUpload: File = null;
imageUrl: string;
jaid;
GetEmployeeref
Empdocument;
loading = false;
  constructor(private fb: FormBuilder, private router: Router ,private service: InterviewService,  private toster: ToastrService,  private route: ActivatedRoute) { 
    this.jaid = route.snapshot.params["id"];
    console.log(this.jaid);
   
  }

  ngOnInit() {
    this.PersonaldetailsForm = this.fb.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      msts: [''],
      cno:['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*')]],
      dob: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      acno :['', [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern('[0-9]*')]],
      pcno:['', [Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')]],
      passno:[''],
      bolgrp:[''],
      Radd:['', Validators.required],
      pcode:['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')]],
    }); 

      // bank details
      this.bankdetailsForm = this.fb.group({    
      bname:['', Validators.required],
      bahname:['', Validators.required],
      bano:['', Validators.required], 
      nrcode:['', Validators.required],
      badd:['', Validators.required],
      
    });

    //  EXPERIENCE DETAILS
    this.experiencedetailsForm=this.fb.group({
      cpyname1:['', Validators.required],
      period :['', Validators.required],
    });

    // Reference details
    this.referencedetailsForm=this.fb.group({
      refname1:['', Validators.required],
      ref1contno:['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*')]],
   
  });

  //  other details
  this.otherdetailsForm=this.fb.group({
    alpfno:['', Validators.required],
    uanno:['', Validators.required],
    pfno:['']

  });

  // DOCUMENTS SUBMITTED (To be uploaded)
  this.documentssubmittedForm=this.fb.group({
    documentname:['', Validators.required],
    documentfile:['', Validators.required],
    
  });

  // job details
  this.jobdetailsForm=this.fb.group({
    jdone:['', Validators.required],
    doj:['', Validators.required],
    cofdt:['', Validators.required],
    Location:['', Validators.required],
    Project:[''],
    reptsto:['']
  });
 
  }

  personaldetails(){
    const data: Employeeclass=new Employeeclass();
    // data.jaid = this.jaid;
    data.firstname = this.PersonaldetailsForm.value.fname;
    data.middlename = this.PersonaldetailsForm.value.mname;
    data.lastname =this.PersonaldetailsForm.value.lname;
    data.maritalstatus= this.PersonaldetailsForm.value.msts;
    data.contactno= this.PersonaldetailsForm.value.cno;
    data.dob= this.PersonaldetailsForm.value.dob;
    data.email= this.PersonaldetailsForm.value.email;
    data.aadharnumber= this.PersonaldetailsForm.value.acno;
    data.pancard= this.PersonaldetailsForm.value.pcno;
    data.passportno= this.PersonaldetailsForm.value.passno;
    data.bloodgroup= this.PersonaldetailsForm.value.bolgrp;
    data.address= this.PersonaldetailsForm.value.Radd;
    data.pincode= this.PersonaldetailsForm.value.pcode;
    console.log(data);
    this.service.AddJobEmployee(this.jaid,data).subscribe((res) => {
      this.submited.emit(res.toString());
      this.toster.success('Personal Details Submited');
    }
    );
  }


  bankdetails(){
    const data:Employeeclass= new Employeeclass();
    data.bankname=this.bankdetailsForm.value.bname;
    data.baccholdername= this.bankdetailsForm.value.bahname;
    data.accno= this.bankdetailsForm.value.bano;
    data.neftcode= this.bankdetailsForm.value.nrcode;
    data.branchadd= this.bankdetailsForm.value.badd;
    console.log(data);
    this.service.UpdateJobEmployee(this.jaid,data).subscribe((res) => {

      this.submited.emit(res.toString());
      this.toster.success('Bank Details Submited');
    }
    );

  }

  experiencedetails(){
   const data:Experienceclass=new Experienceclass();
   data.empid = this.jaid
   data.companynameme= this.experiencedetailsForm.value.cpyname1;
   data.period= this.experiencedetailsForm.value.period;
   console.log(data);
   this.service.AddEmployeeExperience(data).subscribe((res) => {

      this.submited.emit(res.toString());
      this.toster.success('Experience Details Submited');
      this.experiencedetailsForm.reset();
     this.service.GetEmployeeExperience(this.jaid).subscribe((data: any) => {
        this.GetEmployeeExperience = data;
        console.log(this.GetEmployeeExperience);
      });
    }
    );

  }

  
  referencedetails(){
    const EmpR:Referanceclass= new Referanceclass();
    EmpR.empid= this.jaid
    EmpR.refname=this.referencedetailsForm.value.refname1;
    EmpR.contactno=this.referencedetailsForm.value.ref1contno;
    console.log(EmpR);
    this.service.InsertEmployeeReference(EmpR).subscribe((res) => {
      this.submited.emit(res.toString());
      this.toster.success('Reference Details Submited');
      this.referencedetailsForm.reset();
      this.service.GetEmployeeReference(this.jaid).subscribe((data: any) => {
        this.GetEmployeeref = data;
        console.log(this.GetEmployeeref);
      });
    }
    );
   }

  otherdetails(){
    const data:Employeeclass=new Employeeclass();
    data.alreadypf=this.otherdetailsForm.value.alpfno;
    data.uannumber= this.otherdetailsForm.value.uanno;
    data.pfnumber= this.otherdetailsForm.value.pfno;
    console.log(data);
    this.service.UpdateJobEmployee(this.jaid,data).subscribe((res) => {

      this.submited.emit(res.toString());
      this.toster.success('Other Details Submited');
    }
    );

  }

  jobdetails(){
    const data:Employeeclass= new Employeeclass();
    data.empid = this.jaid;
    data.joingdone= this.jobdetailsForm.value.jdone;
    data.doj= this.jobdetailsForm.value.doj;
    data.details= this.jobdetailsForm.value.cofdt;
    data.location=this.jobdetailsForm.value.Location;
    data.project= this.jobdetailsForm.value.Project;
    data.reportsto= this.jobdetailsForm.value.reptsto;
    console.log(data);
    this.service.AddEmployeejobdetail(data).subscribe((res) => {

      this.submited.emit(res.toString());
      this.toster.success('Job Details Submited');
      this.router.navigate(['/Thank-YouEmpdetails']);
    }
    );

  }

  DocumentFileInput(file: FileList) {
    this.DocumentUpload = file.item(0);
    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.DocumentUpload);    
}

documentsubmit() {
  this.loading = true;
  var document = this.documentssubmittedForm.value.documentname;
  console.log(document,this.DocumentUpload)
   this.service.UploadEmployeeDocument(this.jaid,null,document,this.DocumentUpload).subscribe((res) => {
     this.submited.emit(res.toString());
     this.documentssubmittedForm.reset();
     console.log(res.toString());
     if (res.toString() === "Failure")
     {
      this.loading = false;
       this.toster.error ("File size Should Be less then 5 MB");
     }
    else 
     {
       this.toster.success ("File Submitted SuccessFully");
       this.loading = false;
       this.service.GetEmployeeDocument(this.jaid).subscribe((data: any) => { this.Empdocument = data 
       
         });
     }
   },
     error => {
       console.log(error);
     });
 }


}
