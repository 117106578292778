import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Level1Component } from './level1/level1.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { Level2Component } from './level2/level2.component';
import { ThankyouempdeailsComponent } from './thankyouempdeails/thankyouempdeails.component';


const routes: Routes = [
  { path: '', redirectTo: 'Apply-Job', pathMatch: 'full'},
  { path: 'Apply-Job', component: Level1Component},
  { path: 'Thank-You', component: ThankyouComponent},
  { path: 'EmployeeDetails/:id', component: Level2Component},
  { path: 'Thank-YouEmpdetails', component: ThankyouempdeailsComponent},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
