export class Jobqualification {
    constructor(
        public empid:string="",
        public degree:string="",
        public university:string="",
        public year	:string="",
        public classes:string="",	

    ){}
}
