export class Employeeclass {

    constructor(
       
        public  firstname: string = "",
       public  middlename: string = "",
       public  lastname: string = "",
       public  maritalstatus: string = "",
       public  contactno: string = "",
       public  altcontactno: string = "",
       public dob: Date = new Date(),
       public  email: string = "",
       public  pancard: string = "",
       public  aadharnumber: string = "",
       public  passportno: string = "",
       public  bloodgroup: string = "",
       public  address: string = "",
       public  state: string = "",
       public  district: string = "",
       public  city: string = "",
       public  pincode: number = 0,
       public  bankname: string = "",
       public  baccholdername: string = "",
       public  branch: string = "",
       public  accno: string = "",
       public  neftcode: string = "",
       public  branchadd: string = "",
       public  alreadypf: string = "",
       public  pfnumber: string = "",
       public  ipnumber: string = "",
       public  uannumber: string = "",
       public  doj : Date = new Date(),	
       public joingdone: string = "",
       public  details: string = "",
       public  location: string = "",
       public  project: string = "",
       public  reportsto: string = "",
       public  empid: string = "",
       ){}
}
