import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Jobapplication } from 'src/app/ClassFile/jobapplication';
import { InterviewService } from 'src/app/services/interview.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Jobqualification } from 'src/app/classfile/jobqualification';
// import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-level1',
  templateUrl: './level1.component.html',
  styleUrls: ['./level1.component.css']
})
export class Level1Component implements OnInit {
  PersonaldetailsForm: FormGroup;
  fname: FormControl;
  mname: FormControl;
  lname: FormControl;
  location: FormControl;
  contactno: FormControl;
  relocate: FormControl;
  address: FormControl;
  post: FormControl;
  qualificationForm: FormGroup;
  bpassyear: FormControl;
  percentage: FormControl;
  Univname: FormControl;
  degree: FormControl;
  workexpeForm: FormGroup;
  totalexperience: FormControl;
  currentctc: FormControl;
  negotiable: FormControl;
  ngoname: FormControl;
  ngoexperience: FormControl;
  expectedctc: FormControl;
  totalrelevant: FormControl;
  cv: FormControl;
  jperiod: FormControl;
  ifyesngo: FormControl;
  uploadcvForm: FormGroup;
  submited = new EventEmitter<string>();
  id;
  jaid;
  imageUrl: string;
  fileToUpload: File = null;
  // selectedFile: File = null;
  intQualification;
  vacancy;
  loading = false;
  clicked = false;

  constructor(private fb: FormBuilder, private router: Router, private service: InterviewService, private toster: ToastrService) {
    service.VacancyList().subscribe((data: any) => {
      this.vacancy = data;
      // console.log(this.vacancy);
    });
   }

  ngOnInit() {
    this.PersonaldetailsForm = this.fb.group({

      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      contactno: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*')]],
      location: [''],
      relocate: ['', Validators.required],
      address: ['', Validators.required],
      post: ['', Validators.required],
    });
    this.qualificationForm = this.fb.group({
      bpassyear: ['', Validators.required],
      Univname: ['', Validators.required],
      percentage: ['', Validators.required],
      degree: ['', Validators.required],
    });
    this.workexpeForm = this.fb.group({
      totalexperience: [''],
      currentctc:  ['', [Validators.required, Validators.pattern('[0-9]*')]],
      negotiable: [''],
      expectedctc:  ['', [Validators.required, Validators.pattern('[0-9]*')]],
      totalrelevant: [''],
      jperiod: [''],
      ngoname: [''],
      ifyesngo: [''],
      ngoexperience: ['', Validators.required],
    });
    this.uploadcvForm = this.fb.group({
      cv: ['', Validators.required],
    });
  }

  ApplyJob() {
    const data: Jobapplication = new Jobapplication();
      data.firstname = this.PersonaldetailsForm.value.fname;
      data.middlename = this.PersonaldetailsForm.value.mname;
      data.lastname = this.PersonaldetailsForm.value.lname;
      data.contactno = this.PersonaldetailsForm.value.contactno;
      data.location = this.PersonaldetailsForm.value.location;
      data.currentaddress = this.PersonaldetailsForm.value.address;
      data.appliedjobpost =  this.PersonaldetailsForm.value.post;
      data.relocate = this.PersonaldetailsForm.value.relocate;
     console.log(data);
     this.service.InsertInterviewApplication(data).subscribe((res) => {
      this.submited.emit(res.toString());
     this.toster.success('Personal Details Submited');
     console.log("my id"+res.toString());
    var id= localStorage.setItem('jaid',res.toString());
     console.log("my new id"+ localStorage.getItem('jaid'));
    }
    );
 }

 Qualification() {
  this.jaid = localStorage.getItem('jaid');
  const data: Jobqualification = new Jobqualification();
    data.empid = this.jaid;
    data.year = this.qualificationForm.value.bpassyear;
    data.university = this.qualificationForm.value.Univname;
    data.classes = this.qualificationForm.value.percentage;
    data.degree = this.qualificationForm.value.degree;
    console.log(data);
    this.service.InsertInterviewQualification(data).subscribe((res) => {

      this.submited.emit(res.toString());
      this.toster.success('Qualification Details Submited');
      this.qualificationForm.reset();
      this.service.GetInterviewQualification(this.jaid).subscribe((data: any) => {
        this.intQualification = data;

      })

    }
    );
}

Workexperience() {
 this.jaid = localStorage.getItem('jaid');
console.log("myyy new function id"+this.jaid)
const data: Jobapplication = new Jobapplication();
  data.totexp = this.workexpeForm.value.totalexperience;
  data.totrelaexp = this.workexpeForm.value.totalrelevant;
  data.ngoexp = this.workexpeForm.value.ngoexperience;
  data.currctc = this.workexpeForm.value.currentctc;
  data.expctc = this.workexpeForm.value.expectedctc;
  data.negotiable = this.workexpeForm.value.negotiable;
  data.ngoname = this.workexpeForm.value.ngoname;
  data.joinperiod = this.workexpeForm.value.jperiod;
  data.roledescription = this.workexpeForm.value.ifyesngo;
  console.log(data);
  this.service.UpdateInterviewApplication(this.jaid,data).subscribe((res) => {

    this.submited.emit(res.toString());
    this.toster.success('Work Experience Submited');
  }
  );
}

handleFileInput(file: FileList) {
this.fileToUpload = file.item(0);
var reader = new FileReader();
reader.onload = (event: any) => {
  this.imageUrl = event.target.result;
}
reader.readAsDataURL(this.fileToUpload);
}

uploadcv(){
  this.loading = true;
this.jaid = localStorage.getItem('jaid');
console.log(this.fileToUpload)
this.service.UploadJobCV(this.jaid,this.fileToUpload).subscribe((res) => {

  this.submited.emit(res.toString());
  // this.uploadcvForm.reset();
  if (res.toString() === "Failure")
  {
    this.loading = false;
  this.toster.error ("File size Should Be less then 5 MB");
  }
 else 
  {
    this.toster.success('CV Uploaded succesfully');
    this.loading = false;
    this.router.navigate(['/Thank-You']);
  }
}
);

}

}
