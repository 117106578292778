export class Referanceclass {
    constructor(
        public empid: string = "",
        public refname : string = "",
      
        public contactno: string = "",


    ){}
    }
