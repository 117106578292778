import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterviewService {
  constructor(private http: HttpClient) {
  }
  
  InsertInterviewApplication(data: any){
 return this.http.post(environment.apiUrl+"Employee/"+"InsertInterviewApplication/", data);
}


UpdateInterviewApplication(jaid: string, data: any) {
   return this.http.post(environment.apiUrl+"Employee/" + "UpdateInterviewApplication/" + jaid, data);
 }
 
  InsertInterviewQualification(data: any){
 return this.http.post(environment.apiUrl+"Employee/"+"InsertInterviewQualification/", data);
}

 GetInterviewQualification(jaid:string) {
 return this.http.get(environment.apiUrl+"Employee/" + "GetInterviewQualification/" + jaid)
 } 
 
 
 UploadJobCV(jaid: string,fileToUpload: File) {
   const formData: FormData = new FormData();
   formData.append('Image', fileToUpload);
   return this.http.post(environment.apiUrl + "Employee/" + "UploadJobCV/" + jaid,formData)
 }

 VacancyList() {
  return this.http.get(environment.apiUrl+"Employee/" + "VacancyList")
}

InterviewApplicationMail(jaid: string) {
  return this.http.get(environment.apiUrl+"Employee/" + "InterviewApplicationMail/" + jaid)
}

AddJobEmployee(jaid: string, data: any) {
  return this.http.post(environment.apiUrl+"Employee/" + "AddJobEmployee/" + jaid, data);
}
UpdateJobEmployee(jaid: string, data: any) {
  return this.http.post(environment.apiUrl+"Employee/" + "UpdateJobEmployee/" + jaid, data);
}
AddEmployeeExperience(data: any) {

  return this.http.post(environment.apiUrl+"Employee/"+"AddEmployeeExperience/", data);
}
GetEmployeeExperience(jaid: string) {
  return this.http.get(environment.apiUrl+"Employee/" + "GetEmployeeExperience/" + jaid)
}
InsertEmployeeReference(EmpR:any) {
  return this.http.post(environment.apiUrl+"JankariPortal/" + "InsertEmployeeReference", EmpR);
  } 
  GetEmployeeReference(empid:string) {
    return this.http.get(environment.apiUrl+"JankariPortal/" + "GetEmployeeReference/" + empid)
    } 
  AddEmployeejobdetail(data: any) {

    return this.http.post(environment.apiUrl+"Employee/"+"AddEmployeejobdetail/", data);
  }
  // UploadEmployeeDocument(this.employeeId,'',document,this.DocumentUpload)
  UploadEmployeeDocument(empid: string, fineyear: string,documenttype:string,fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + "Employee/" + "UploadEmployeeDocument/" + empid+"/"+fineyear+"/"+documenttype,formData)
  }
  GetEmployeeDocument(empid: string): any {
    return this.http.get(environment.apiUrl+"Employee/" + "GetEmployeeDocumentNew/" + empid)
  }
}
