import { Component, OnInit } from '@angular/core';
import { InterviewService } from '../services/interview.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {
  jaid;
  constructor(private service: InterviewService) {
    this.jaid = localStorage.getItem('jaid');
    service.GetInterviewQualification(this.jaid).subscribe((data: any) => {
      console.log( data ); 
    });
   }

  ngOnInit() {
  }

}
