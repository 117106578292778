export class Jobapplication {

    constructor(
	public firstname:string="",	
    public middlename:string="",	
    public lastname	:string="",
    public contactno:string="",	
    public location:string="",
    public altcontactno	:string="",
    public currentaddress:string="",	
    public appliedjobpost:string="",	
    public relocate	:string="",

    public totexp	:string="",
    public totrelaexp	:string="",
    public ngoexp:string="",	
    public roledescription:string="",	
    public currctc: number =0,
    public expctc: number =0,
    public negotiable:string="",	
    public joinperiod:string="",
    public ngoname:string="",	
    ){}
}
